import { ButtonLink, Divider, RiArrowRightLine, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { Capsule, CapsuleContent, CapsuleIcon, CapsuleLabel, CapsuleTrend } from '@/components';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsuleV1_2/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { usePathSet } from '@/hooks/usePathSet';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useIsProjectCalculating } from '@/pages/shared/hooks/useIsProjectCalculating';
import { usePlotId } from '@/pages/shared/hooks/usePlotId';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';
import { printShortMonth } from '@/utils/formatting/date';

export const WaterHistoricalStatsTile = () => {
  const isLargeScreen = useScreenSize() === 'large';
  return (
    <Stack className='gap-8 p-6 lg:justify-center lg:gap-4 lg:p-8 lg:pr-2' data-testid='water-historical-stats-tile'>
      <WaterHoldingCapacity />

      {isLargeScreen && <Divider className='border-dashed' />}

      <SoilMoisture />

      {isLargeScreen && <Divider className='border-dashed' />}

      <Trends />
    </Stack>
  );
};

const WaterHoldingCapacity = () => {
  const { t } = useTranslation();
  const pathSet = usePathSet();
  const pathname = useLocation();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');
  const projectId = useProjectId();
  const plotId = usePlotId();
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId });
  const waterHoldingCapacityLatestAnalysis = getFact<number>(
    analysisType === 'total' ? R1FactType.r1_water_holding_capacity_total : R1FactType.r1_water_holding_capacity_per_ha,
  );
  const whc1yrTrend = getFact<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_whc_increase_previous_1y_uplift_total_pct_change
      : R1FactType.r1_water_whc_increase_previous_1y_uplift_per_ha_pct_change,
  );
  const whc1yrTrendDisplay = `${useDisplayNumber(whc1yrTrend?.value ?? 0, {
    signDisplay: 'always',
  })} %`;

  const waterHoldingCapacity1yChange = getFact<number>(
    analysisType === 'total' ? R1FactType.r1_whc_previous_1y_uplift_total : R1FactType.r1_whc_previous_1y_uplift_per_ha,
  );

  const isProjectCalculating = useIsProjectCalculating();

  const lastMeasuredDate = printLatestDate([waterHoldingCapacityLatestAnalysis?.measured_at]);

  const whc1yChangeInitialMeasuredAt = lastMeasuredDate;
  const whc1yChangePreviousMeasuredAt = printLatestDate([waterHoldingCapacity1yChange?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.waterHoldingCapacity')}
        supplementaryInfo={!isProjectCalculating && `${latestAnalysisTranslation} ${lastMeasuredDate}`}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.title'),
          body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={latestAnalysisTranslation}
          fact={waterHoldingCapacityLatestAnalysis}
          trend={
            typeof whc1yrTrend?.value === 'number' && (
              <CapsuleTrend variant={whc1yrTrend.value < 0 ? 'negative' : 'positive'}>
                {whc1yrTrendDisplay}
              </CapsuleTrend>
            )
          }
          capsuleIconProps={{
            icon: getNCChangeIcon(waterHoldingCapacityLatestAnalysis),
            variant: getNCChangeIconVariant(waterHoldingCapacityLatestAnalysis),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacityLatestAnalysis.title'),
            body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacityLatestAnalysis.body', {
              date: lastMeasuredDate,
            }),
          }}
        />
        {!!waterHoldingCapacity1yChange?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.oneYearChange')}
            fact={waterHoldingCapacity1yChange}
            capsuleIconProps={{
              icon: getNCChangeIcon(waterHoldingCapacity1yChange),
              variant: getNCChangeIconVariant(waterHoldingCapacity1yChange),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.water.explainers.oneYearChange.title'),
              body: t('shared.ncaDetail.details.water.explainers.oneYearChange.body', {
                date: whc1yChangeInitialMeasuredAt,
                previousDate: whc1yChangePreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'whc-since-previous-analysis-tooltip',
              },
            }}
          />
        )}
      </Stack>
      {typeof whc1yrTrend?.value === 'number' && (
        <Stack className='mt-4 gap-4 rounded-2xl bg-neutral-black-4 px-4 py-6'>
          <span className='typography-h4'>{t('shared.ncaDetail.details.water.explainers.whcTrend.title')}</span>
          <Trans
            i18nKey='shared.ncaDetail.details.water.explainers.whcTrend.body'
            values={{
              trendPercent: whc1yrTrendDisplay,
            }}
            components={{
              p: <p className='typography-body1 text-text-secondary' />,
            }}
          />
          <ButtonLink
            to={buildPath(pathSet.impactProjections, { pathParams: { projectId } })}
            state={{
              previousPath: pathname,
            }}
            variant='outline'
            className='w-fit'
          >
            {t('shared.ncaDetail.details.water.explainers.whcTrend.buttonLabel')}
          </ButtonLink>
        </Stack>
      )}
    </div>
  );
};

const SoilMoisture = () => {
  const { t } = useTranslation();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');

  const plotId = usePlotId();
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId });
  const soilMoistureLatestAnalysis = getFact(
    analysisType === 'total'
      ? R1FactType.r1_soil_moisture_latest_analysis_total
      : R1FactType.r1_soil_moisture_latest_analysis_per_ha,
  );

  const isProjectCalculating = useIsProjectCalculating();

  const lastMeasuredDate = printLatestDate([soilMoistureLatestAnalysis?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.soilMoisture')}
        supplementaryInfo={!isProjectCalculating && `${latestAnalysisTranslation} ${lastMeasuredDate}`}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.soilMoisture.title'),
          body: t('shared.ncaDetail.details.water.explainers.soilMoisture.body'),
        }}
      />
      <NCFactCapsule
        label={latestAnalysisTranslation}
        fact={soilMoistureLatestAnalysis}
        capsuleIconProps={{
          icon: getNCChangeIcon(soilMoistureLatestAnalysis),
          variant: getNCChangeIconVariant(soilMoistureLatestAnalysis),
        }}
        infoPopoverProps={{
          title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
          body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
            date: lastMeasuredDate,
          }),
        }}
      />
    </div>
  );
};

const Trends = () => {
  const { t } = useTranslation();

  const plotId = usePlotId();
  const { getFact } = usePlotReportForPlot({ plotId });

  const soilMoistureTrendChange = getFact(R1FactType.r1_soil_moisture_trend_change);
  const precipitationTrendChange = getFact(R1FactType.r1_precipitation_trend_change);

  const isProjectCalculating = useIsProjectCalculating();

  const lastMeasuredDate = printLatestDate([
    soilMoistureTrendChange?.measured_at,
    precipitationTrendChange?.measured_at,
  ]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.droughtRisk')}
        supplementaryInfo={
          !isProjectCalculating && `${t('shared.ncaDetail.details.labels.latestEstimates')} ${lastMeasuredDate}`
        }
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.droughtRisk.title'),
          body: t('shared.ncaDetail.details.water.explainers.droughtRisk.body', {
            currentMonth: printShortMonth(Date.now()),
          }),
        }}
      />
      <Stack direction='row' className='flex-wrap justify-between gap-8'>
        <SoilMoistureChange />
        <PrecipitationChange />
      </Stack>
    </div>
  );
};

const SoilMoistureChange = () => {
  const { t } = useTranslation();

  const plotId = usePlotId();
  const { getFact } = usePlotReportForPlot({ plotId });

  const soilMoistureTrendChange = getFact(R1FactType.r1_soil_moisture_trend_change);

  const label = t('shared.ncaDetail.details.water.labels.soilMoisture');

  const infoPopoverProps = {
    title: t('shared.ncaDetail.details.water.explainers.soilMoisturePercentageChange.title'),
    body: t('shared.ncaDetail.details.water.explainers.soilMoisturePercentageChange.body', {
      currentMonth: printShortMonth(Date.now()),
    }),
  };

  if (soilMoistureTrendChange?.value === 0) {
    return (
      <Capsule
        label={<CapsuleLabel infoPopoverProps={infoPopoverProps}>{label}</CapsuleLabel>}
        content={<CapsuleContent>{t('global.analysis.noSignificantTrend')}</CapsuleContent>}
        thumbnail={<CapsuleIcon icon={<RiArrowRightLine />} variant='default' />}
      />
    );
  }

  return (
    <NCFactCapsule
      label={label}
      fact={soilMoistureTrendChange}
      capsuleIconProps={{
        icon: getNCChangeIcon(soilMoistureTrendChange),
        variant: getNCChangeIconVariant(soilMoistureTrendChange),
      }}
      infoPopoverProps={infoPopoverProps}
    />
  );
};

const PrecipitationChange = () => {
  const { t } = useTranslation();

  const plotId = usePlotId();
  const { getFact } = usePlotReportForPlot({ plotId });

  const precipitationTrendChange = getFact(R1FactType.r1_precipitation_trend_change);

  const label = t('shared.ncaDetail.details.water.labels.precipitation');

  const infoPopoverProps = {
    title: t('shared.ncaDetail.details.water.explainers.precipitationPercentageChange.title'),
    body: t('shared.ncaDetail.details.water.explainers.precipitationPercentageChange.body', {
      currentMonth: printShortMonth(Date.now()),
    }),
  };

  if (precipitationTrendChange?.value === 0) {
    return (
      <Capsule
        label={<CapsuleLabel infoPopoverProps={infoPopoverProps}>{label}</CapsuleLabel>}
        content={<CapsuleContent>{t('global.analysis.noSignificantTrend')}</CapsuleContent>}
        thumbnail={<CapsuleIcon icon={<RiArrowRightLine />} variant='default' />}
      />
    );
  }

  return (
    <NCFactCapsule
      label={label}
      fact={precipitationTrendChange}
      capsuleIconProps={{
        icon: getNCChangeIcon(precipitationTrendChange),
        variant: getNCChangeIconVariant(precipitationTrendChange),
      }}
      infoPopoverProps={infoPopoverProps}
    />
  );
};
