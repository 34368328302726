import { ButtonLink, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsuleV1_2/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { usePathSet } from '@/hooks/usePathSet';
import { useIsProjectCalculating } from '@/pages/shared/hooks/useIsProjectCalculating';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';

export const WaterCardStatsTile = () => {
  return (
    <Stack className='gap-8 p-6 lg:justify-center lg:gap-4 lg:p-8 lg:pr-2' data-testid='water-stats-tile'>
      <WaterHoldingCapacity />
    </Stack>
  );
};

const WaterHoldingCapacity = () => {
  const { t } = useTranslation();
  const pathSet = usePathSet();
  const pathname = useLocation();
  const projectId = useProjectId();

  const { analysisType } = useNCCardContext();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');

  const whcLatestAnalysis = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_latest_analysis_total
      : R1FactType.r1_water_holding_capacity_latest_analysis_per_ha,
  );
  const whc1YearTrend = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_whc_increase_previous_1y_uplift_total_pct_change
      : R1FactType.r1_water_whc_increase_previous_1y_uplift_per_ha_pct_change,
  );
  const whc1YearTrendDisplay = `${useDisplayNumber(whc1YearTrend?.value ?? 0, { signDisplay: 'always' })} %`;

  const whc1yrChange = useNCData<number>(
    analysisType === 'total' ? R1FactType.r1_whc_previous_1y_uplift_total : R1FactType.r1_whc_previous_1y_uplift_per_ha,
  );

  const isProjectCalculating = useIsProjectCalculating();

  const latestAnalysisDate = printLatestDate([whcLatestAnalysis?.measured_at]);

  const whcSincePreviousAnalysisInitialMeasuredAt = printLatestDate([whcLatestAnalysis?.measured_at]);
  const whcSincePreviousAnalysisPreviousMeasuredAt = printLatestDate([whc1yrChange?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.waterHoldingCapacity')}
        supplementaryInfo={!isProjectCalculating && `${latestAnalysisTranslation} ${latestAnalysisDate}`}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.title'),
          body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={latestAnalysisTranslation}
          fact={whcLatestAnalysis}
          capsuleIconProps={{
            icon: getNCChangeIcon(whcLatestAnalysis),
            variant: getNCChangeIconVariant(whcLatestAnalysis),
          }}
          trend={
            typeof whc1YearTrend?.value === 'number' && (
              <CapsuleTrend variant={whc1YearTrend.value < 0 ? 'negative' : 'positive'}>
                {whc1YearTrendDisplay}
              </CapsuleTrend>
            )
          }
          infoPopoverProps={
            whcLatestAnalysis?.measured_at
              ? {
                  title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacityLatestAnalysis.title'),
                  body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacityLatestAnalysis.body', {
                    date: latestAnalysisDate,
                  }),
                }
              : null
          }
        />
        {!!whc1yrChange?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.oneYearChange')}
            fact={whc1yrChange}
            capsuleIconProps={{
              icon: getNCChangeIcon(whc1yrChange),
              variant: getNCChangeIconVariant(whc1yrChange),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.water.explainers.oneYearChange.title'),
              body: t('shared.ncaDetail.details.water.explainers.oneYearChange.body', {
                date: whcSincePreviousAnalysisInitialMeasuredAt,
                previousDate: whcSincePreviousAnalysisPreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'whc-1yr-change-tooltip',
              },
            }}
          />
        )}
      </Stack>
      {typeof whc1YearTrend?.value === 'number' && (
        <Stack className='mt-4 gap-4 rounded-2xl bg-neutral-black-4 px-4 py-6'>
          <span className='typography-h4'>{t('shared.ncaDetail.details.water.explainers.whcTrend.title')}</span>
          <Trans
            i18nKey='shared.ncaDetail.details.water.explainers.whcTrend.body'
            values={{
              trendPercent: whc1YearTrendDisplay,
            }}
            components={{
              p: <p className='typography-body1 text-text-secondary' />,
            }}
          />
          <ButtonLink
            to={buildPath(pathSet.impactProjections, { pathParams: { projectId } })}
            state={{
              previousPath: pathname,
            }}
            variant='outline'
            className='w-fit'
          >
            {t('shared.ncaDetail.details.water.explainers.whcTrend.buttonLabel')}
          </ButtonLink>
        </Stack>
      )}
    </div>
  );
};
