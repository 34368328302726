import { Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsuleV1_2/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useNCData } from '@/pages/shared/hooks/useNCData';

export const WaterStatsTile = () => {
  return (
    <Stack className='gap-8 p-6 md:justify-center md:gap-4 md:p-8 md:pr-2' data-testid='water-stats-tile'>
      <WaterUpliftPotential />
    </Stack>
  );
};

const WaterUpliftPotential = () => {
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();

  const whc1yearUpliftPotential = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_whc_increase_1y_uplift_potential_total_change
      : R1FactType.r1_water_whc_increase_1y_uplift_potential_per_ha_change,
  );
  const whc1YearTrend = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_whc_increase_1y_uplift_potential_total_pct_change
      : R1FactType.r1_water_whc_increase_1y_uplift_potential_per_ha_pct_change,
  );

  const whc10yearUpliftPotential = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_whc_increase_10y_uplift_potential_total_change
      : R1FactType.r1_water_whc_increase_10y_uplift_potential_per_ha_change,
  );
  const whc10YearTrend = useNCData<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_whc_increase_10y_uplift_potential_total_pct_change
      : R1FactType.r1_water_whc_increase_10y_uplift_potential_per_ha_pct_change,
  );

  const latestAnalysisDate = printLatestDate([
    whc1yearUpliftPotential?.measured_at,
    whc10yearUpliftPotential?.measured_at,
  ]);

  const whc1YearTrendDisplay = `${useDisplayNumber(whc1YearTrend?.value ?? 0, { signDisplay: 'always' })} %`;
  const whc10YearTrendDisplay = `${useDisplayNumber(whc10YearTrend?.value ?? 0, { signDisplay: 'always' })} %`;

  return (
    <Stack className='gap-8 lg:mt-4 lg:justify-center lg:gap-6' data-testid='water-uplift-stats'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.upliftPotentialForWaterHoldingCapacity')}
        supplementaryInfo={`${t('shared.ncaDetail.details.labels.latestAnalysis')} ${latestAnalysisDate}`}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.upliftPotentialForWaterHoldingCapacity.title'),
          body: t('shared.ncaDetail.details.water.explainers.upliftPotentialForWaterHoldingCapacity.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.1YearPotential')}
          fact={whc1yearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(whc1yearUpliftPotential),
            variant: getNCChangeIconVariant(whc1yearUpliftPotential),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.1YearPotential.title'),
            body: t('shared.ncaDetail.details.water.explainers.1YearPotential.body'),
            popoverTriggerProps: {
              'data-testid': 'whc-1-year-uplift-potential-popover',
            },
          }}
          trend={
            typeof whc1YearTrend?.value === 'number' && (
              <CapsuleTrend variant={whc1YearTrend.value < 0 ? 'negative' : 'positive'}>
                {whc1YearTrendDisplay}
              </CapsuleTrend>
            )
          }
        />
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.10YearPotential')}
          fact={whc10yearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(whc10yearUpliftPotential),
            variant: getNCChangeIconVariant(whc10yearUpliftPotential),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.10YearPotential.title'),
            body: t('shared.ncaDetail.details.water.explainers.10YearPotential.body'),
            popoverTriggerProps: {
              'data-testid': 'whc-10-year-uplift-potential-popover',
            },
          }}
          trend={
            typeof whc10YearTrend?.value === 'number' && (
              <CapsuleTrend variant={whc10YearTrend.value < 0 ? 'negative' : 'positive'}>
                {whc10YearTrendDisplay}
              </CapsuleTrend>
            )
          }
        />
      </Stack>
      {typeof whc10YearTrend?.value === 'number' && (
        <Stack className='mt-4 gap-4 rounded-2xl bg-neutral-black-4 px-4 py-6'>
          <span className='typography-h4'>
            {t('shared.ncaDetail.details.water.explainers.whcUpliftPotential.title')}
          </span>
          <Trans
            i18nKey='shared.ncaDetail.details.water.explainers.whcUpliftPotential.body'
            values={{
              trendPercent: whc10YearTrendDisplay,
            }}
            components={{
              p: <p className='typography-body1 text-text-secondary' />,
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
